import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const HomePage = ({ aboutusRef }) => {
  const [formVisible, setFormVisible] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    pujaName: '',
    location: '',
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};

    // Validate Name
    if (!formData.name) newErrors.name = 'Name is required';

    // Validate Email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email regex
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = 'Invalid email address';
      toast.error('Please enter a valid email address');
    }

    // Validate Phone Number
    if (!formData.phone) {
      newErrors.phone = 'Phone number is required';
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = 'Phone number must be exactly 10 digits';
      toast.error('Please enter a 10-digit phone number');
    }

    // Validate Puja Name
    if (!formData.pujaName) newErrors.pujaName = 'Puja Name is required';

    // Validate Location
    if (!formData.location) newErrors.location = 'Location is required';

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const data = JSON.stringify(formData);

      const config = {
        method: 'post',
        url: 'https://api.teertham.org/api/web/contact-us',
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          alert('Form submitted successfully!');
          setFormVisible(false);
          setFormData({ name: '', email: '', phone: '', pujaName: '', location: '' });
          setErrors({});
        })
        .catch((error) => {
          console.error(error);
          alert('Failed to submit the form. Please try again.');
        });
    }
  };

  return (
    <div className="w-full bg-white">
      <ToastContainer />
      {/* Hero Section */}
      <section className="flex flex-col md:flex-row items-center justify-between w-full max-w-[calc(100%_-_400px)] mx-auto p-4">
        {/* Left Content */}
        <div className="flex flex-col items-start space-y-4 md:w-1/2 text-center md:text-left">
          <h1 className="text-3xl md:text-5xl font-bold text-gray-800">
            <span className="text-orange-500 font-cormorant">Teertham</span>
          </h1>
          <h3 className="text-xl md:text-2xl font-bold text-gray-800 font-open-sans italic">
            Experience Divine Rituals <br />
          </h3>
          <p className="text-gray-600 text-sm md:text-base">
            Get a divine puja experience at home, performed by experienced & certified Acharyas; with highest quality of puja samagri & havan kund pre-packed and delivered to your home.
          </p>
          <div className="flex flex-col space-y-4 justify-center md:justify-start">
            {/* <button
              className="bg-orange-500 text-white px-4 py-2 rounded-md"
              onClick={() => setFormVisible(true)}
            >
              Coming Soon - Get your Free VIP Puja Invite
            </button> */}
            <p className="bg-orange-500 text-white px-4 py-2 rounded-md">Download the Teertham App</p>
            <div className="flex space-x-4">
              <a href='https://play.google.com/store/apps/details?id=teertham.com&pcampaignid=web_share' target='_blank'><img src="/playstore.png" alt="Get it on Google Play" className="w-24 h-auto" /></a>
              <a href='https://apps.apple.com/in/app/teertham-puja-samagri-home/id6740483257' target='_blank'><img src="/appstore.png" alt="Download on the App Store" className="w-24 h-auto" /></a>
            </div>
          </div>
        </div>

        {/* Right Content */}
        <div className="flex items-center justify-center mt-8 md:mt-0 md:w-1/2">
          <img src="/banner.png" alt="Mobile Image 2" className="w-full h-auto" />
        </div>
      </section>

      {/* Form Modal */}
      {formVisible && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-md w-11/12 md:w-1/2">
            <h2 className="text-2xl font-bold mb-4">Add your details</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700">Name</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                />
                {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Email</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                />
                {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Phone</label>
                <input
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                />
                {errors.phone && <p className="text-red-500 text-sm">{errors.phone}</p>}
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Name of the Puja</label>
                <input
                  type="text"
                  name="pujaName"
                  value={formData.pujaName}
                  onChange={handleInputChange}
                  placeholder='Name of the Puja I want to experience'
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                />
                {errors.pujaName && <p className="text-red-500 text-sm">{errors.pujaName}</p>}
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Location</label>
                <input
                  type="text"
                  name="location"
                  value={formData.location}
                  onChange={handleInputChange}
                  placeholder='Area & City. For eg. Vasant Vihar, New Delhi'
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                />
                {errors.location && <p className="text-red-500 text-sm">{errors.location}</p>}
              </div>
              <div className="flex justify-between items-center">
                <button
                  type="submit"
                  className="bg-orange-500 text-white px-4 py-2 rounded-md"
                >
                  Submit
                </button>
                <button
                  type="button"
                  className="text-gray-700 underline"
                  onClick={() => setFormVisible(false)}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomePage;
import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { LoadScript, Autocomplete } from "@react-google-maps/api";

// Move libraries outside to prevent performance warnings
const GOOGLE_MAPS_API_KEY = "AIzaSyAnVLYPFx51uzZQ8C8eD7Owt05eeOFGF78";
const libraries = ["places"];

const BookingAddress = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const bookingId = queryParams.get("razorpay_payment_link_reference_id");

  const [formData, setFormData] = useState({
    fullAddress: "",
    locality: "",
    direction: "",
  });
  const [errors, setErrors] = useState({});
  const [bookingData, setBookingData] = useState({});
  const [addressSubmit, setAddressSubmit] = useState(false);
  const autocompleteRef = useRef(null);

  useEffect(() => {
    if (bookingId) getBookingData();
  }, [bookingId]);

  const handleInputChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handlePlaceSelect = useCallback(() => {
    const place = autocompleteRef.current.getPlace();
    if (place && place.formatted_address) {
      setFormData((prev) => ({ ...prev, fullAddress: place.formatted_address }));
      setErrors((prev) => ({ ...prev, fullAddress: "" })); // Clear error on valid selection
    }
  }, []);

  const validateForm = () => {
    const newErrors = {};
    if (!formData.fullAddress) newErrors.fullAddress = "This field is required";
    if (!formData.locality) newErrors.locality = "This field is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const getBookingData = async () => {
    try {
      const { data } = await axios.get(`https://api.teertham.org/api/web/booking-data/${bookingId}`);
      setBookingData(data.result);
    } catch (error) {
      console.error("Error fetching booking data:", error);
    }
  };

  const handleAddressSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const payload = { ...formData, userId: bookingData?.userId, bookingId };
      await axios.post("https://api.teertham.org/api/web/booking-address", payload);
      alert("Form submitted successfully!");
      setAddressSubmit(true);
      getBookingData();
    } catch (error) {
      console.error("Submission failed:", error);
      alert("Failed to submit the form. Please try again.");
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      {bookingData && (
        <div className="max-w-md w-full bg-white shadow-lg rounded-2xl p-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-4">
            Booking Confirmed
            {!bookingData?.address?.fullAddress && (
              <span className="text-sm text-gray-500">
                {" "}
                Please submit your full address for puja below
              </span>
            )}
          </h2>

          <div className="mb-4">
            <p className="text-sm text-gray-500">Booking Details:</p>
            <p className="text-sm text-gray-700">
              <span className="font-semibold">Booking ID:</span> {bookingId.slice(-4)}
            </p>
            <p className="text-sm text-gray-700">
              <span className="font-semibold">Puja Name:</span> {bookingData?.service?.title}
            </p>
            <p className="text-sm text-gray-700">
              <span className="font-semibold">Duration:</span> {bookingData?.service?.duration}
            </p>
            <p className="text-sm text-gray-700">
              <span className="font-semibold">Payment Status:</span> Done
            </p>
            <p className="text-sm text-gray-700">
              <span className="font-semibold">Amount:</span> ₹ {bookingData?.service?.price}
            </p>
            {bookingData?.address?.fullAddress && (
              <p className="text-sm text-gray-700">
                <span className="font-semibold">Full Address:</span> {bookingData?.address?.fullAddress}, {bookingData?.address?.locality}, {bookingData?.address?.direction}
              </p>
            )}
          </div>

          {!bookingData?.address?.fullAddress && (
            <form onSubmit={handleAddressSubmit} className="space-y-4">
              <div>
                <label htmlFor="fullAddress" className="block text-gray-700">
                  House/Flat/Block No.
                </label>
                <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY} libraries={libraries}>
                  <Autocomplete onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)} onPlaceChanged={handlePlaceSelect}>
                    <input
                      type="text"
                      name="fullAddress"
                      value={formData.fullAddress}
                      onChange={handleInputChange}
                      placeholder="Enter your address"
                      className="w-full px-3 py-2 border border-gray-300 rounded-md"
                    />
                  </Autocomplete>
                </LoadScript>
                {errors.fullAddress && <p className="text-red-500 text-sm">{errors.fullAddress}</p>}
              </div>

              <div>
                <label htmlFor="locality" className="block text-gray-700">
                  Apartment/Road/Area
                </label>
                <input
                  type="text"
                  name="locality"
                  value={formData.locality}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                />
                {errors.locality && <p className="text-red-500 text-sm">{errors.locality}</p>}
              </div>

              <div>
                <label htmlFor="direction" className="block text-gray-700">
                  Directions To Reach
                </label>
                <textarea
                  name="direction"
                  value={formData.direction}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  rows="3"
                  placeholder=""
                />
              </div>

              <button type="submit" className="w-full bg-indigo-600 text-white py-2 px-4 rounded-lg shadow hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                Submit Address
              </button>
            </form>
          )}
        </div>
      )}
    </div>
  );
};

export default BookingAddress;
